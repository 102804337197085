import PropTypes from "prop-types";

export default function Text({ textColor, text }) {
  const textColorDefault = "#000000";

  return (
    <div
      className="text-lg sm:text-base"
      style={{ color: textColor || textColorDefault }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}

Text.propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.string,
};

import Text from "components/atoms/Text";
import SearchBanner from "components/molecules/SearchBanner/SearchBanner";
import PropTypes from "prop-types";
import Image from "next/future/image";
import { useMediaQuery } from "react-responsive";
import dynamic from "next/dynamic";
import { CircularProgress } from "@mui/material";

const ButtonPrimary = dynamic(() => import("components/atoms/Button"), {
  loading: () => (
    <div className="flex justify-center m-3">
      <CircularProgress color="inherit" />
    </div>
  ),
  ssr: false
});
export default function Banner({
  textBanner,
  uriButton,
  titleButton,
  colorButton,
  colorTextButton,
  classname,
  tabletSrc,
  desktopSrc,
  desktopXlSrc,
  desktop2xlSrc,
  imgUri,
  isHome
}) {
  const home = isHome;
  const containerSize = home
    ? "h-[45rem] md:h-[29rem] xl:h-[26rem]"
    : "md:h-[20rem] lg:min-h-[30rem] overflow-hidden";
  const imageContainer = home
    ? "-top-[27rem] md:-top-[24rem] lg:-top-[20rem] xl:-top-[10rem] w-full"
    : "h-auto";
  const imageSize = home
    ? "h-[74rem] md:h-[60rem] lg:h-[57rem] xl:h-[45rem] w-full"
    : "h-full";

  const isTablet = useMediaQuery({ maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1280 });
  const isDesktopXl = useMediaQuery({ minWidth: 1281, maxWidth: 1440 });
  const isDesktop2xl = useMediaQuery({ minWidth: 1441 });

  const getImageAttributes = () => {
    let src, width, height;

    switch (true) {
      case isTablet:
        src = tabletSrc.href;
        width = 1024;
        height = 576;
        break;
      case isDesktop:
        src = desktopSrc.href;
        width = 1280;
        height = 720;
        break;
      case isDesktopXl:
        src = desktopXlSrc.href;
        width = 1920;
        height = 1080;
        break;
      case isDesktop2xl:
        src = desktop2xlSrc.href;
        width = 2560;
        height = 1440;
        break;
      default:
        src = desktopXlSrc.href;
        width = 1920;
        height = 1080;
    }

    return { src, width, height };
  };

  const { src, width, height } = getImageAttributes();

  return (
    <div
      className={`relative flex flex-col justify-start sm:justify-center items-center w-full h-full ${containerSize} ${classname}`}
    >
      <div
        style={{ objectFit: isDesktopXl ? "cover" : "contain" }}
        className={`absolute z-10 ${imageContainer} ${imageSize} bg-gray`}
      >
        <Image
          priority
          src={src}
          alt={imgUri}
          width={width}
          height={height}
          layout="responsive"
          className={"brightness-50 object-cover " + imageSize}
        />
      </div>
      <div className="relative z-50 md:w-3/5 my-5 md:mt-10 p-6 text-center">
        <Text textColor="#ffffff" text={textBanner} />
        {uriButton ? (
          <div className="inline-block w-fit h-10 my-8">
            <ButtonPrimary
              url={uriButton}
              textButton={titleButton}
              backgroundButton={colorButton}
              colorTextButton={colorTextButton}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {home && <SearchBanner />}
    </div>
  );
}

export function BannerTitle({ titleBanner, backgroundImage, imgHeight }) {
  const height = imgHeight || "h-96";
  return (
    <div
      className={`${height} bg-cover bg-no-repeat bg-center bg-gray-dark bg-opacity-50 bg-blend-overlay flex justify-center`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div
        className={`${
          height ? "" : "mt-9"
        } flex items-center p-6 justify-center text-center`}
      >
        {imgHeight ? (
          <h3 className="text-2xl text-white font-bold">{titleBanner}</h3>
        ) : (
          <h1 className="text-3xl md:text-6xl text-white font-bold">
            {titleBanner}
          </h1>
        )}
      </div>
    </div>
  );
}

Banner.propTypes = {
  mobileSrc: PropTypes.object,
  tabletSrc: PropTypes.object,
  desktopSrc: PropTypes.object.isRequired,
  desktopXlSrc: PropTypes.object,
  desktop2xlSrc: PropTypes.object,
  textBanner: PropTypes.string,
  uriButton: PropTypes.string,
  titleButton: PropTypes.string,
  colorButton: PropTypes.string,
  colorTextButton: PropTypes.string,
  titleBanner: PropTypes.string,
};

import {
  FormControl,
  InputLabel,
  MenuItem, Box,
  Select,
} from "@mui/material";
import PropTypes from "prop-types";
import {SelectStyles as styles, BannerStyles} from "./SelectList.styles";

export function SelectList({ menuItems, label, onChange, defaultValue, formLabel, sx, name }) {

  return (
    <Box sx={styles}> 
      <FormControl className="w-full">
        <InputLabel focused={false} htmlFor={formLabel} shrink={true} sx={sx}>
          {label}
        </InputLabel>
        {
          <Select
            className={`!font-semibold bg-gray-smooth !rounded-[10px]`}
            value={defaultValue}
            name={name}
            label={label}
            onChange={onChange}
            inputProps={{ id: formLabel }}
          >
            {menuItems?.map((menuItem) => (
              <MenuItem key={menuItem.value} value={menuItem.value}>
                {menuItem.name}
              </MenuItem>
            ))}
          </Select>
        }
      </FormControl>
    </Box>
  );
}

export function BannerSelectList({ menuItems, label, onChange, defaultValue, placeholder, home }){
  return (
    <Box sx={BannerStyles}>
      <FormControl>
        <InputLabel
          sx={{background: home ? "#fff" : "#F9F9F9"}}
          htmlFor={'select-search-bar-homepage'} shrink={true}
        >{label}</InputLabel>
        <Select
          sx={{width: {sm: '170px', md: '200px', lg: '300px'}}}
          inputProps={{id: 'select-search-bar-homepage'}}
          value={defaultValue}
          label={label}
          onChange={onChange}
          placeholder={placeholder}
        >
          {
            menuItems?.map((menuItem) => (
              <MenuItem
                key={menuItem.value}
                value={menuItem.value}
              >
                {menuItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  )
}

SelectList.propTypes = {
  label: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any
};

import { useMemo, useState } from 'react';
import {BannerSelectList} from "components/atoms/SelectList/SelectList";
import { Box, useMediaQuery, Tab, Tabs, Skeleton } from '@mui/material';
import Button from "components/atoms/Button";
import Filters from "lib/Filters";
import treatmentTypeJSON from "/json/treatment_type.json";
import propertyTypeJSON from "/json/property_type.json";
import { SearchBannerStyles as styles } from './SearchBanner.styles';
import FiltersBuilder from "/lib/FiltersBuilder";
import UniversalSearchAutocomplete from "../UniversalSearch/UniversalSearchAutocomplete";
import dynamic from "next/dynamic";

const DCustomIcon = dynamic(() => import('../../atoms/CustomIcon'),{
  loading: () => (
    <Skeleton
      variant="rectangular"
      width={20}
      height={40}
    />
  ),
  ssr: false
});

const DynamicDivider = dynamic(() => import('@mui/material/Divider'),{
  ssr: true
});

export default function SearchBanner() {

  const filters = Filters.filters;
  const treatmentDefault = treatmentTypeJSON.length > 0 ? treatmentTypeJSON[0] : {};
  const formattedPropertyTypeJSON = useMemo(() => propertyTypeJSON.map((property) => {
    return {
      value: {clave: property.clave, nombre: property.nombre},
      name: property.nombre
    };
  }).sort((a, b) => {
    const labelA = a.name.toUpperCase();
    const labelB = b.name.toUpperCase();

    if(labelA < labelB) return -1;
    if(labelA > labelB) return 1;
    return 0;
  }), []);

  const tablet = useMediaQuery('(min-width:700px)');

  //State and handle for Tabs
  const [tabValue, setTabValue] = useState(treatmentDefault);

    // Set default filters
  Filters.setFilter(
    "field_treatement_type_id",
    [{value: tabValue.clave, label: tabValue.nombre}]
  );
  //Build the url based on actual filters
  const newPathName = FiltersBuilder.buildListingUrlByFilters(filters);

    //State to handle the redirect to search
  const [searchPathName, setSearchPathName] = useState(`/buscar/${newPathName}`);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    Filters.setFilter(
      "field_treatement_type_id",
      [{value: newValue?.clave, label: newValue?.nombre}]
    );
    updateSearchPathName();
  }

  //State and handle SelectList
  const [SelectValue, setSelectValue] = useState('');
  const SelectHandleChange = (event, newValue) => {
    setSelectValue(event.target.value);
    Filters.setFilter(
      "field_property_type_id",
      [{value: event.target.value.clave, label: event.target.value.nombre}]
    );
    updateSearchPathName();
  }

  function updateSearchPathName() {
    const newPathName = FiltersBuilder.buildListingUrlByFilters(filters);
    setSearchPathName(`/buscar/${newPathName}`);
  }

  function addFilter(key, values) {
    if(Array.isArray(values) && values.length >= 0) {
      Filters.setFilter(key, values);
    } else {
      Filters.addFilter(key, values);
    }
    updateSearchPathName();
  }

  return (
    <div className="relative z-50 my-8">
      <Box sx={styles}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
        >
          {treatmentTypeJSON &&
            treatmentTypeJSON.map((treatment) => (
              <Tab
                key={treatment.clave}
                value={treatment}
                label={treatment.nombre}
              />
            ))}
        </Tabs>
      </Box>
      <div className="flex flex-col xl:items-center md:flex-row rounded-r-[5px] rounded-bl-[5px] gap-2 lg:gap-4 bg-white p-5 w-auto">
        <BannerSelectList
          home={true}
          label={"Tipo de propiedad"}
          onChange={SelectHandleChange}
          menuItems={formattedPropertyTypeJSON}
          defaultValue={SelectValue}
          placeholder="Selecciona el tipo de propiedad"
        />
        <DynamicDivider
          className="my-2 mx-0 md:my-0 md:mx-3"
          orientation={tablet ? "vertical" : "horizontal"}
          variant={"fullWidth"}
          flexItem
        />
        <div className="search-banner sm:w-[170px] lg:w-[200px] xl:w-[300px] h-auto">
          <UniversalSearchAutocomplete
            selectedStates={filters.field_state.values}
            selectedMunicipality={filters.field_municipality.values}
            selectedLocalidades={filters.field_localidad.values}
            callback={addFilter}
            home={true}
          />
        </div>
        <div className="sm:w-[170px] lg:w-[200px] xl:w-[300px] h-[56px]">
          <Button
            textButton={"Buscar"}
            backgroundButton={"#B12028"}
            startIcon={<DCustomIcon iconName="search" viewBox="0 0 40 50" className="h-10"/>}
            url={searchPathName}
          />
        </div>
      </div>
    </div>
  );
}

export const SelectStyles = () => {
  return {
    width: "100%",
    marginBottom: {
      xs: "10px",
      sm: "2px"
    },
    "& .MuiFormLabel-root": {
      color: "#000000",
      fontWeight: 600,
      width: "100%",
    },

    fieldset: {
      borderColor: "black !important",
    },

    svg: {
      color: "#000000",
      fontSize: '3rem'
    },

    "& .MuiSelect-select": {
      background: "#F9F9F9",
    },
  };
};

export const BannerStyles = () => {
  return {
    '& .MuiFormLabel-root': {
      color: '#000000',
      fontWeight: 600,
      width: 'auto',
      "&.Mui-focused": {
        color: "#000000"
      }
    },

    '& .MuiFormLabel-root:focused': {
      color: '#000000',
    },

    fieldset: {
      borderColor: "black !important",
    },

    '& .Mui-focused': {
      textAlign: 'left',
    },

    '& .MuiFormLabel-filled': {
      textAlign: 'left'
    },

    '& .MuiSvgIcon-root': {
      color: '#000000',
      fontSize: '3rem'
    },

    '& .MuiInputBase-root': {
      backgroundColor: '#FFFFFF',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000000'
      }
    },

    '& .MuiSelect-select': {
      overflow: 'visible !important'
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A7A8A9'
    },

    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#A7A8A9'
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
  }
}

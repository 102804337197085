export const SearchBannerStyles = () => {
    return {
        "& .MuiTabs-indicator": {
            display: "none"
        },
        '& .MuiButtonBase-root': {
            backgroundColor: 'white',
            opacity: '0.5',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            fontWeight: 700,
            width: '150px',
            color: '#000000',
            '&.Mui-selected': {
                color: '#B12028',
                opacity: '1'
            }
        },
    }
}